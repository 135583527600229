import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Home() {
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);

    const aboutClick = () => {
        if (isExpanded2 === false && isExpanded3 === false) {
            setIsExpanded1((prevExpanded) => !prevExpanded);
        }
        else {
            setIsExpanded2(false);
            setIsExpanded3(false);
            setTimeout(() => {
                setIsExpanded1((prevExpanded) => !prevExpanded);
            }, 300);
        }
    };
    const contactClick = () => {
        if (isExpanded1 === false && isExpanded3 === false) {
            setIsExpanded2((prevExpanded) => !prevExpanded);
        }
        else {
            setIsExpanded1(false);
            setIsExpanded3(false);
            setTimeout(() => {
                setIsExpanded2((prevExpanded) => !prevExpanded);
            }, 300);
        }
    };
    const regionsClick = () => {
        if (isExpanded1 === false && isExpanded2 === false) {
            setIsExpanded3((prevExpanded) => !prevExpanded);
        }
        else {
            setIsExpanded1(false);
            setIsExpanded2(false);
            setTimeout(() => {
                setIsExpanded3((prevExpanded) => !prevExpanded);
            }, 300);
        }
    };

    return (
        <div className="bg-white">
            <div id="head">
                <img src="../../assets/ellipse.svg" alt="green blob" className="absolute z-0 w-[100dvw] h-[500px]" />
                <div className="flex w-full justify-center pt-[60px] mb-[19px]">
                    <img src="../../assets/logo.svg" alt="logo" className="relative z-1" />
                </div>
                <h1 className="relative z-9 text-center text-white text-[36px] font-bold"> هِيم | Heme </h1>
                <div className='flex justify-center'>
                    <h1 className="relative z-9 text-center max-w-[460px] text-white text-[32px] font-semibold"> تتبّع طبقك: استكشف مصادر لحوم المطاعم </h1>
                </div>
            </div>
            <section className={`flex flex-col z-3 items-center pt-[26px] mt-[340px] w-full bg-primary-300 transition-all duration-300 ${isExpanded1 ? 'h-[670px] sm:h-[550px]' : 'h-[300px]'} ${isExpanded2 ? 'h-[450px] sm:h-[450px]' : 'h-[300px]'} ${isExpanded3 ? 'h-[670px] sm:h-[550px]' : 'h-[300px]'}`}>
                <h1 className="mb-[20px] text-center text-[24px] font-bold text-primary-900"> ابحث عن مطعم </h1>
                <Link to="/search">
                    <button className="flex justify-center gap-[15px] h-[32px] w-[117px] pt-[4px] shadow-lg rounded-[10px] bg-primary-800 hover:translate-y-1 ease-in-out duration-300">
                        <div className="text-white font-semibold"> اذهب </div>
                        <svg className="mt-[3px]" xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                            <path d="M8.7 17.4522L0.214462 8.96667L8.7 0.481133L10.5855 2.36667L3.98553 8.96667L10.5855 15.5667L8.7 17.4522Z" fill="white" />
                        </svg>
                    </button>
                </Link>
                <nav className="mb-[20px] flex justify-center gap-[40px] mt-[50px] text-primary-900 font-semibold">
                    <button onClick={aboutClick}> من نحن </button>
                    <button onClick={contactClick}> تواصل معنا </button>
                    <button onClick={regionsClick}> المناطق </button>
                </nav>
                <div className={`px-[12%] w-full transition-all duration-300 ${isExpanded1 ? 'h-[460px] sm:h-[350px]' : 'h-0'} overflow-hidden bg-primary-400`}>
                    <h1 className='mt-[30px] text-primary-900 text-[26px] font-bold'> حول هِيم </h1>
                    <p className='mt-[20px]'>
                        في هيم، نحن مصدرك المخصص لكشف أصول لحوم المطاعم. اسمنا "هِيم" مستوحى من الجزيء الأساسي الموجود في أنسجة الحيوانات، والمسؤول عن اللون الأحمر في اللحوم. زي الهيم اللي في قلب كل لحمة، فإن مهمتنا هي جلب الشفافية إلى قلب تجربتك التناولية.
                    </p>
                    <p className='mt-[20px]'>
                        شغفنا هو ربطك بمصادر لحوم المطاعم. معرفة مصدر الطعام أمر أساسي لاتخاذ قرارات مدروسة ودعم التجارات الوطنية. من خلال منصتنا، يمكنك تتبع رحلة اللحم من المزرعة إلى المائدة، مما يمكنك من اتخاذ قرارات واعية حول وجباتك.
                    </p>
                </div>
                <div className={`px-[12%] w-full transition-all duration-300 ${isExpanded2 ? 'h-[300px]' : 'h-0'} overflow-hidden bg-primary-400`}>
                    <h1 className='mt-[30px] text-primary-900 text-[26px] font-bold'> تواصل </h1>
                    <div className='flex flex-col w-[250px]'>
                        <address dir="ltr" className='text-end mt-[20px]'>+966 55 543 7300</address>
                        <address dir="ltr" className='text-end'> hemeinquiry@gmail.com </address>
                    </div>
                </div>
                <div className={`px-[12%] w-full transition-all duration-300 ${isExpanded3 ? 'h-[460px] sm:h-[350px]' : 'h-0'} overflow-hidden bg-primary-400`}>
                    <h1 className='mt-[30px] text-primary-900 text-[26px] font-bold'> اختياراتنا </h1>
                    <p className='mt-[20px]'>
                        في هيم، تتمثل قائمة مطاعمنا حاليًا في المنطقة الشرقية في المملكة العربية السعودية، وتحديدًا في القطيف والدمام والخبر. نحن مكرسون لتزويدكم بمعلومات شاملة حول مصادر اللحوم في هذه المناطق، مما يتيح لكم اتخاذ قرارات مدروسة حول تجارب تناول الطعام.
                    </p>
                    <p className='mt-[20px]'>
                        رؤيتنا هي توسيع نطاق تغطيتنا وتضمين المزيد من المناطق قريبًا، لذا كن على اطلاع دائمًا على التحديثات بينما نستمر في تطوير قاعدة بياناتنا. ومع توسعنا، يظل هدفنا هو ربطكم بمصادر اللحوم في المطاعم على مستوى المملكة العربية السعودية، وتعزيز الشفافية ودعم المجتمعات المحلية.
                    </p>
                </div>
            </section>
            <div className='z-2'>
                <img
                    src="../../assets/screenshot.png"
                    alt='screenshot'
                    className='absolute end-[37px] top-[300px] w-[230px] rounded-[30px]'
                />
                <img
                    src='../../assets/mockup-iphone (1).png'
                    alt='mockup iphone frame'
                    className='absolute end-[0] top-[269px] w-[300px]'
                />
            </div>
        </div>
    );
}

export default Home;