import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config'; // Assuming you have your Firestore instance set up in a separate file
import { Link } from 'react-router-dom';

function Profile() {

    const { restaurantId } = useParams();
    const [restaurant, setRestaurant] = useState(null);

    useEffect(() => {
        const fetchRestaurant = async () => {
            try {
                const restaurantRef = doc(db, 'restaurant', restaurantId);
                const docSnap = await getDoc(restaurantRef);

                if (docSnap.exists()) {
                    setRestaurant({ ...docSnap.data(), id: docSnap.id });
                } else {
                    console.log('Restaurant not found!');
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchRestaurant();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!restaurant) {
        return <div className="mt-[50px] text-center font-bold"> جاري التحميل... </div>;
    }

    return (
        <div className='pb-[20px] min-h-[100dvh]'>
            <header className="fixed top-0 flex gap-[16px] bg-primary-600 h-[80px] w-full px-[26px] py-[16px] shadow-md">
                <img
                    src="../../assets/logo-nav.svg"
                    alt="logo"
                    className="h-[50px] w-[50px]"
                />
                <h2 className="relative text-center text-white mt-[6px] text-[24px] font-semibold"> هِيم | Heme </h2>
                <div className="flex-grow">
                </div>
                <Link to='/search'>
                    <svg className="mt-[14px]" xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                        <path d="M8.7 17.4522L0.214462 8.96667L8.7 0.481133L10.5855 2.36667L3.98553 8.96667L10.5855 15.5667L8.7 17.4522Z" fill="white" />
                    </svg>
                </Link>
            </header>
            <div className='max-w-[550px] mx-auto'>
                <div className="mt-[110px] px-[26px]">
                    <div className="flex gap-[23px] mb-[21px]">
                        <div id="logo-container" className="h-[115px] min-w-[115px] max-w-[115px] bg-[#F0F0F0] rounded-[12px] shadow-md">
                            <img
                                src={restaurant.imageUrl}
                                alt={restaurant.titleEn}
                                className="rounded-[12px]"
                            />
                        </div>
                        <div>
                            <h2 className="text-[20px] font-semibold"> {restaurant.titleEn} </h2>
                            <h2 className="text-[20px] font-semibold mb-[8px]"> {restaurant.titleAr} </h2>
                            <div className="flex gap-[8px]">
                                <svg className="mt-[3px] min-w-[14px] min-h-[18px]" xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                                    <path d="M7.00139 8.95767C6.54778 8.95767 6.16318 8.79428 5.84758 8.46749C5.53197 8.14069 5.37417 7.74177 5.37417 7.27073C5.37417 6.79968 5.53206 6.40028 5.84783 6.07254C6.16361 5.74479 6.54906 5.58092 7.00418 5.58092C7.45931 5.58092 7.84337 5.74488 8.15635 6.0728C8.46934 6.40073 8.62583 6.80101 8.62583 7.27363C8.62583 7.74627 8.46848 8.14511 8.15379 8.47013C7.83911 8.79516 7.45498 8.95767 7.00139 8.95767ZM7.00393 15.4807C5.2522 13.8332 3.9543 12.3588 3.11023 11.0574C2.26617 9.75603 1.84414 8.53879 1.84414 7.40569C1.84414 5.79688 2.34211 4.47958 3.33803 3.45377C4.33396 2.42798 5.55558 1.91509 7.00289 1.91509C8.45021 1.91509 9.67218 2.42798 10.6688 3.45377C11.6654 4.47958 12.1637 5.79688 12.1637 7.40569C12.1637 8.53879 11.7347 9.754 10.8768 11.0513C10.0188 12.3486 8.72788 13.8251 7.00393 15.4807ZM7.00393 18C9.34461 15.9589 11.0958 14.0822 12.2575 12.3699C13.4192 10.6575 14 9.00282 14 7.40569C14 5.15219 13.2967 3.35454 11.8902 2.01272C10.4837 0.670907 8.85497 0 7.00393 0C5.15512 0 3.5256 0.670907 2.11536 2.01272C0.70512 3.35454 0 5.15219 0 7.40569C0 9.00282 0.582152 10.6575 1.74646 12.3699C2.91075 14.0822 4.66324 15.9589 7.00393 18Z" fill="black" />
                                </svg>
                                <a href={restaurant.addressUrl}>
                                    <address className="not-italic underline text-text-light">
                                        {restaurant.address}
                                    </address>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-[13px]">
                        <svg className="ms-[14px] mt-[4px]" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <path d="M9.06977 18.1306C7.81794 18.1306 6.64152 17.8927 5.54052 17.4168C4.43951 16.9409 3.47855 16.294 2.65764 15.4758C1.83673 14.6577 1.18856 13.6985 0.713147 12.5981C0.237716 11.4977 0 10.3219 0 9.07073C0 7.8196 0.237708 6.64412 0.713125 5.54428C1.18856 4.44445 1.83673 3.48544 2.65764 2.66724C3.47855 1.84904 4.43999 1.19995 5.54197 0.719971C6.64393 0.23999 7.82137 0 9.0743 0C10.3272 0 11.5023 0.23999 12.5994 0.719971C13.6966 1.19995 14.6548 1.84812 15.4741 2.66446C16.2934 3.48082 16.9409 4.43961 17.4168 5.54083C17.8927 6.64206 18.1306 7.81872 18.1306 9.0708C18.1306 10.3229 17.8931 11.4993 17.418 12.6001C16.9429 13.7008 16.2952 14.6606 15.475 15.4795C14.6547 16.2984 13.6943 16.945 12.5938 17.4192C11.4933 17.8935 10.3186 18.1306 9.06977 18.1306ZM8.14321 16.3758V14.5944C7.63279 14.5944 7.20664 14.4073 6.86475 14.0329C6.52286 13.6586 6.35191 13.2197 6.35191 12.716V11.7659L1.94213 7.35571C1.86921 7.64738 1.81817 7.93128 1.789 8.20741C1.75984 8.48355 1.74525 8.76514 1.74525 9.05218C1.74525 10.9169 2.3511 12.5511 3.56278 13.9547C4.77447 15.3583 6.30128 16.1653 8.14321 16.3758ZM14.4756 14.0504C14.7881 13.7087 15.0627 13.343 15.2992 12.9533C15.5357 12.5637 15.7357 12.1559 15.8993 11.7298C16.0629 11.3037 16.1848 10.8673 16.265 10.4207C16.3452 9.974 16.3853 9.51603 16.3853 9.04676C16.3853 7.53509 15.9707 6.15854 15.1413 4.91708C14.312 3.67563 13.2024 2.76736 11.8125 2.19227V2.57366C11.8125 3.07387 11.6369 3.50992 11.2857 3.88183C10.9345 4.25373 10.5088 4.43968 10.0088 4.43968H8.14321V6.31437C8.14321 6.56475 8.04477 6.7681 7.8479 6.9244C7.65102 7.08069 7.42739 7.15883 7.17701 7.15883H5.40218V9.04676H10.9618C11.2062 9.04676 11.4091 9.14214 11.5705 9.3329C11.7318 9.52367 11.8125 9.74081 11.8125 9.98434V12.716H12.7284C13.1431 12.716 13.5091 12.84 13.8265 13.0879C14.1438 13.3358 14.3602 13.6567 14.4756 14.0504Z" fill="black" />
                        </svg>
                        <a className="max-w-[200px] h-[60px] break-words" href={restaurant.social}>
                            {restaurant.social}
                        </a>
                    </div>
                </div>
                <div className="flex flex-col gap-[14px]">
                    {restaurant.items && restaurant.items.length > 0 && restaurant.items.map((item, index) => (
                        <div className="w-[100% - 52px] h-[134px] mx-[26px] py-[10px] px-[12px] rounded-[12px] shadow-lg">
                            <div className="flex gap-[17px]">
                                <h3 className="text-[16px] font-semibold pb-[10px]"> {item} </h3>
                                <label className="text-primary-600 font-[14px]"> {restaurant.sources[index]} </label>
                            </div>
                            <h2 className="text-[20px] font-semibold pb-[12px]"> {restaurant.headers[index]} </h2>
                            <p className="text-[14px] text-text-light font-regular"> {restaurant.paragraphs[index]} </p>
                        </div>
                    ))}
                    {(!restaurant.items || restaurant.items.length === 0) && (
                        <div className="w-[100% - 52px] h-[96px] mx-[26px] py-[10px] px-[12px] rounded-[12px] shadow-lg text-center">
                            <p className='mt-[25px]'> .معلومات هذا المطعم غير متوفرة حاليًا </p>
                        </div>
                    )}

                    <div className=" mx-[26px] py-[10px] px-[12px] rounded-[12px] shadow-lg bg-primary-800">
                        <div className="flex gap-[13px]">
                            <h3 className="text-white text-[16px] font-semibold"> الشهادات </h3>
                        </div>
                        {restaurant.certDate && (
                            <h4 className="text-white text-[14px] font-medium"> منذ {restaurant.certDate} </h4>
                        )}
                        <p className='text-white mt-[12px]'>
                            موقع HemeKSA.com يعمل كمنصة لربط المستخدمين بمعلومات حول مصادر لحوم المطاعم. نحرص على توفير بيانات دقيقة ومحدثة من خلال التواصل المباشر مع المطاعم ومشاركة الفواتير/الشهادات التي نتلقاها منهم. ومع ذلك، من الضروري الاعتراف بأن دقة وموثوقية المعلومات المقدمة من المطاعم قد تختلف.
                            <Link to="../disclaimer" className='mb-[22px] text-primary-300 underline inline-block mx-[12px]'> إقرأ المزيد </Link>
                        </p>
                        <div className='mt-[10px] flex flex-col gap-[12px] w-full min-h-[100px] rounded-[12px] shadow-lg bg-primary-800'>
                            {restaurant.certUrl &&
                                <div className='text-white break-words'>
                                    على موقع/حساب المطعم:
                                    <Link to={restaurant.certUrl}> {restaurant.certUrl} </Link>
                                </div>
                            }
                            {restaurant.certs && restaurant.certs.length > 0 && restaurant.certs.map((cert) => (
                                <img
                                    src={restaurant.certs[cert]}
                                    alt="certificate/receipt"
                                    className='rounded-[12px]'
                                />
                            ))}
                            {(!restaurant.certs || restaurant.certs.length === 0) && (!restaurant.certUrl) && (
                                <div className='text-center text-white'>
                                    <p> لا توجد شهادات للعرض </p>
                                    <p> تواصل مع الدعم الفني </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;