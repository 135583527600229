import { Link } from 'react-router-dom';

function disclaimer() {
    return (
        <div className='bg-primary-700 pb-[20px] min-h-[100dvh]'>

            <header className="fixed top-0 flex gap-[16px] bg-primary-600 h-[80px] w-full px-[26px] py-[16px] shadow-md">
                <img
                    src="../../assets/logo-nav.svg"
                    alt="logo"
                    className="h-[50px] w-[50px]"
                />
                <h2 className="relative text-center text-white mt-[6px] text-[24px] font-semibold"> هِيم | Heme </h2>
                <div className="flex-grow">
                </div>
                <Link to='/search'>
                    <svg className="mt-[14px]" xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                        <path d="M8.7 17.4522L0.214462 8.96667L8.7 0.481133L10.5855 2.36667L3.98553 8.96667L10.5855 15.5667L8.7 17.4522Z" fill="white" />
                    </svg>
                </Link>
            </header>
            <section className='max-w-[550px] mx-auto pt-[130px] pb-[100px] px-[26px]'>
                <p className='text-white text-[20px]'>
                    موقع HemeKSA.com يعمل كمنصة لربط المستخدمين بمعلومات حول مصادر لحوم المطاعم. نحرص على توفير بيانات دقيقة ومحدثة من خلال التواصل المباشر مع المطاعم ومشاركة الفواتير/الشهادات التي نتلقاها منهم. ومع ذلك، من الضروري الاعتراف بأن دقة وموثوقية المعلومات المقدمة من المطاعم قد تختلف.
                </p>
                <p className='text-white text-[20px] pt-[20px]'>
                    نعمل كوسيط فقط، حيث نقوم بتحويل المعلومات المحصلة من المطاعم إلى مستخدمينا بأفضل جهد من جانبنا. في حين نسعى لضمان صحة المعلومات، لا يمكننا ضمان دقة أو اكتمال البيانات التي تقدمها المطاعم.
                    صحة المعلومات تعتمد على نزاهة وشفافية كل مطعم على حدة.
                </p>
                <p className='text-white text-[20px] pt-[20px]'>
                    بالإضافة إلى ذلك، لا يتحمل موقع HemeKSA.com أي مسؤولية عن أي تبعات تنجم عن أخطاء أو حوادث أو استخدام المعلومات المقدمة. يتحمل المستخدمون مسؤولية التحقق المستقل من البيانات واتخاذ قرارات مدروسة بناءً على تفضيلاتهم الشخصية ومتطلباتهم الغذائية.
                </p>
                <p className='text-white text-[20px] pt-[20px]'>
                    نحن ملتزمون بتحسين موثوقية وشفافية منصتنا ونرحب بملاحظات المستخدمين لتعزيز دقة المعلومات التي نقدمها.
                </p>
            </section>
        </div>


    )
}

export default disclaimer;
