import { useEffect } from "react";
import { db } from "../firebase-config";
import { getDocs, collection } from "firebase/firestore";
import { useState } from "react";
import { Link } from 'react-router-dom';

function Search() {
    const [restaurants, setRestaurants] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const restaurantCollectionRef = collection(db, "restaurant");
    useEffect(() => {
        const getRestaurants = async () => {
            try {
                const data = await getDocs(restaurantCollectionRef);
                const filteredData = data.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                console.log(filteredData);
                setRestaurants(filteredData);
            } catch (err) {
                console.error(err);
            }
        }

        getRestaurants();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };

    const filteredRestaurants = restaurants.filter((restaurant) =>
        restaurant.titleEn.toLowerCase().includes(searchQuery.toLowerCase()) ||
        restaurant.titleAr.includes(searchQuery.toLowerCase())
    );

    return (
        <div className="pb-[20px] min-h-[100dvh]">
            <header className="fixed top-0 flex gap-[16px] bg-primary-600 h-[80px] w-full px-[26px] pt-[35px] shadow-md">
                <div className="relative w-[550px] mx-auto">
                    <svg className="absolute top-[5px] end-[10px]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19.8859 21.3707L13.2261 14.725C12.7355 15.1294 12.1596 15.4455 11.4983 15.6734C10.837 15.9013 10.126 16.0152 9.36522 16.0152C7.49657 16.0152 5.91437 15.3667 4.61862 14.0696C3.32286 12.7725 2.67497 11.2076 2.67497 9.375C2.67497 7.54238 3.32353 5.97752 4.62065 4.68042C5.91775 3.38332 7.48677 2.73477 9.32772 2.73477C11.1687 2.73477 12.7335 3.38332 14.0223 4.68042C15.3111 5.97752 15.9554 7.54363 15.9554 9.37875C15.9554 10.1118 15.8458 10.8045 15.6266 11.4571C15.4074 12.1096 15.0786 12.7228 14.6402 13.2968L21.325 19.9315L19.8859 21.3707ZM9.3452 14.0348C10.6395 14.0348 11.7347 13.5803 12.6308 12.6714C13.5269 11.7626 13.975 10.6638 13.975 9.375C13.975 8.08623 13.5262 6.98742 12.6287 6.07855C11.7313 5.16967 10.6367 4.71523 9.3452 4.71523C8.03976 4.71523 6.93178 5.16967 6.02125 6.07855C5.11071 6.98742 4.65545 8.08623 4.65545 9.375C4.65545 10.6638 5.11002 11.7626 6.01917 12.6714C6.92832 13.5803 8.037 14.0348 9.3452 14.0348Z" fill="#7B7B7B" />
                    </svg>
                    <input
                        className="h-[33px] w-full rounded-[12px] shadow-lg text-text-light ps-[16px] font-bold"
                        placeholder=" ابحث "
                        value={searchQuery}
                        onChange={handleSearch}
                    >
                    </input>
                </div>
                <Link to="/">
                    <svg className="mt-[5px]" xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                        <path d="M8.7 17.4522L0.214462 8.96667L8.7 0.481133L10.5855 2.36667L3.98553 8.96667L10.5855 15.5667L8.7 17.4522Z" fill="white" />
                    </svg>
                </Link>
            </header>
            <div className='max-w-[550px] mx-auto'>
                <div className="flex flex-col gap-[11px] mt-[105px] px-[20px]">
                    {filteredRestaurants.map((restaurant) =>
                        <Link to={`/profile/${restaurant.id}`}>
                            <div className="overflow-hidden flex gap-[14px] h-[134px] w-full p-[9px] rounded-[12px] shadow-lg">
                                <div id="logo-container" className="h-[115px min-w-[115px] max-w-[115px] bg-[#F0F0F0] rounded-[12px] shadow-md">
                                    <img
                                        src={restaurant.imageUrl}
                                        alt={restaurant.titleEn}
                                        className="rounded-[12px]"
                                    />
                                </div>
                                <div>
                                    <h2 className="text-[18px] font-semibold"> {restaurant.titleEn} </h2>
                                    <h2 className="text-[18px] font-semibold mb-[8px]"> {restaurant.titleAr} </h2>
                                    <div className="flex gap-[8px]">
                                        <svg className="mt-[3px] min-w-[14px] min-h-[18px]" xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                                            <path d="M7.00139 8.95767C6.54778 8.95767 6.16318 8.79428 5.84758 8.46749C5.53197 8.14069 5.37417 7.74177 5.37417 7.27073C5.37417 6.79968 5.53206 6.40028 5.84783 6.07254C6.16361 5.74479 6.54906 5.58092 7.00418 5.58092C7.45931 5.58092 7.84337 5.74488 8.15635 6.0728C8.46934 6.40073 8.62583 6.80101 8.62583 7.27363C8.62583 7.74627 8.46848 8.14511 8.15379 8.47013C7.83911 8.79516 7.45498 8.95767 7.00139 8.95767ZM7.00393 15.4807C5.2522 13.8332 3.9543 12.3588 3.11023 11.0574C2.26617 9.75603 1.84414 8.53879 1.84414 7.40569C1.84414 5.79688 2.34211 4.47958 3.33803 3.45377C4.33396 2.42798 5.55558 1.91509 7.00289 1.91509C8.45021 1.91509 9.67218 2.42798 10.6688 3.45377C11.6654 4.47958 12.1637 5.79688 12.1637 7.40569C12.1637 8.53879 11.7347 9.754 10.8768 11.0513C10.0188 12.3486 8.72788 13.8251 7.00393 15.4807ZM7.00393 18C9.34461 15.9589 11.0958 14.0822 12.2575 12.3699C13.4192 10.6575 14 9.00282 14 7.40569C14 5.15219 13.2967 3.35454 11.8902 2.01272C10.4837 0.670907 8.85497 0 7.00393 0C5.15512 0 3.5256 0.670907 2.11536 2.01272C0.70512 3.35454 0 5.15219 0 7.40569C0 9.00282 0.582152 10.6575 1.74646 12.3699C2.91075 14.0822 4.66324 15.9589 7.00393 18Z" fill="black" />
                                        </svg>
                                        <address className="not-italic underline text-text-light">
                                            {restaurant.address}
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )}
                    <div className="flex gap-[20px] mt-[20px] w-full justify-center">
                        <div className="shrink h-[2px] w-[35%] mt-[12px] bg-gradient-to-r from-[#bbbbbb]"></div>
                        <p className="flex-none text-[#bbbbbb]"> نهاية القائمة </p>
                        <div className="shrink h-[2px] w-[35%] mt-[12px] bg-gradient-to-l from-[#bbbbbb]"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Search;