import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './routes/Home';
import Search from './routes/Search';
import ExamplePage from './routes/Profile';
import Disclaimer from './routes/Disclaimer';

function App() {
  return (
    <Router>
      <Routes>
        {/* Navigation or header can be placed here */}
        <Route exact path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/profile/:restaurantId" element={<ExamplePage />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        {/* Footer or other elements can be placed here */}
      </Routes>
    </Router>
  );
}

export default App;